:root {
    --backgrounds-lines-n-600: #10141b;
    --backgrounds-lines-n-500: #141922;
    --backgrounds-lines-n-400: #1e2631;
    --backgrounds-lines-n-300: #1a222d;
    --backgrounds-lines-n-200: #262e3e;
    --backgrounds-lines-n-100: #3c485a;

    --text-icons-gray-300: #4f5b6d;
    --text-icons-gray-200: #7c868b;
    --text-icons-gray-100: #d1d1d1;
    --text-icons-orange-500: #f90;
    --text-icons-red-500: #ff3636;
    --text-icons-blue-500: #00aeef;
    --text-icons-green-500: #0b0;

    --text-icons-blue-600: #0060cc;

    --Buttons-All-Platforms-Gray-100: #d1d1d1;
    --Buttons-All-Platforms-Gray-200: #4f5b6d;
    --Buttons-All-Platforms-Gray-300: #1e2631;
    --Buttons-All-Platforms-Gray-500: #10141b;

    --Buttons-All-Platforms-btn-action-green: #080;
    --Buttons-All-Platforms-btn-action-green-hover: #090;

    --Buttons-All-Platforms-btn-action-blue: #0060cc;
    --Buttons-All-Platforms-btn-action-blue-hover: #0059bd;

    --Buttons-Red-Outline: #ff2a2a;
    --Buttons-Red-Active: #d22626;
    --Buttons-Red-Hover: #df2828;

    --Buttons-Green-Outline: #0b0;
    --Buttons-Green-Active: #080;
    --Buttons-Green-Hover: #090;

    --Buttons-Orange-Outline: #ff9900;
    --Buttons-Orange-Active: #d37e00;
    --Buttons-Orange-Hover: #e48900;

    --Buttons-Blue-Outline: #0060cc;
    --Buttons-Blue-Active: #0155b3;
    --Buttons-Blue-Hover: #0059bd;

    --App-Accent-Outline: #ff9900;
    --App-Accent-Active: #d37e00;
    --App-Accent-Hover: #e48900;
}

/* #region BACKGROUNDS & LINES */
.n-600 {
    background: var(--backgrounds-lines-n-600) !important;
}
.n-500 {
    background: var(--backgrounds-lines-n-500) !important;
}
.n-400 {
    background: var(--backgrounds-lines-n-400) !important;
}
.n-300 {
    background: var(--backgrounds-lines-n-300) !important;
}
.n-200 {
    background: var(--backgrounds-lines-n-200) !important;
}
.n-100 {
    background: var(--backgrounds-lines-n-100) !important;
}
/* #endregion */

/* #region TEXT & ICONS COLORS */
.text-gray-300 {
    color: var(--text-icons-gray-300) !important;
}
.text-gray-200 {
    color: var(--text-icons-gray-200) !important;
}
.text-gray-100 {
    color: var(--text-icons-gray-100) !important;
}
.text-orange-500 {
    color: var(--text-icons-orange-500) !important;
}
.text-red-500 {
    color: var(--text-icons-red-500) !important;
}
.text-blue-500 {
    color: var(--text-icons-blue-500) !important;
}
.text-blue-600 {
    color: var(--text-icons-blue-600) !important;
}
.text-green-500 {
    color: var(--text-icons-green-500) !important;
}
/* #endregion */

/* #region TEXT & ICONS BACKGROUNDS */
.bg-text-gray-300 {
    background: var(--text-icons-gray-300) !important;
}
.bg-text-gray-200 {
    background: var(--text-icons-gray-200) !important;
}
.bg-text-gray-100 {
    background: var(--text-icons-gray-100) !important;
}
.bg-text-orange-500 {
    background: var(--text-icons-orange-500) !important;
}
.bg-text-red-500 {
    background: var(--text-icons-red-500) !important;
}
.bg-text-blue-500 {
    background: var(--text-icons-blue-500) !important;
}
.bg-text-blue-600 {
    background: var(--text-icons-blue-600) !important;
}
.bg-text-green-500 {
    background: var(--text-icons-green-500) !important;
}
/* #endregion */

/* #region BUTTONS */
.btn-gray-100 {
    background: var(--Buttons-All-Platforms-Gray-100) !important;
}
.btn-gray-200 {
    background: var(--Buttons-All-Platforms-Gray-200) !important;
}
.btn-gray-300 {
    background: var(--Buttons-All-Platforms-Gray-300) !important;
}
.btn-gray-500 {
    background: var(--Buttons-All-Platforms-Gray-500) !important;
}

.btn-green-outline {
    color: var(--Buttons-Green-Outline) !important;
}
.btn-green-active {
    color: var(--Buttons-Green-Active) !important;
}
.btn-green-hover {
    color: var(--Buttons-Green-Hover) !important;
}

.btn-red-outline {
    color: var(--Buttons-Red-Outline) !important;
}
.btn-red-active {
    color: var(--Buttons-Red-Active) !important;
}
.btn-red-hover {
    color: var(--Buttons-Red-Hover) !important;
}

.btn-orange-outline {
    color: var(--Buttons-Orange-Outline) !important;
}
.btn-orange-active {
    color: var(--Buttons-Orange-Active) !important;
}
.btn-orange-hover {
    color: var(--Buttons-Orange-Hover) !important;
}

.btn-blue-outline {
    color: var(--Buttons-Blue-Outline) !important;
}
.btn-blue-active {
    color: var(--Buttons-Blue-Active) !important;
}
.btn-blue-hover {
    color: var(--Buttons-Blue-Hover) !important;
}
/* #endregion */

/* #region APP ACCENT COLORS */
.App-Accent-outline {
    color: var(--App-Accent-Outline) !important;
}
.App-Accent-active {
    color: var(--App-Accent-Active) !important;
}
.App-Accent-hover {
    color: var(--App-Accent-Hover) !important;
}
/* #endregion */

/* #region TEXT & ICONS CUSTOM CSS CLASSES */
/* DONT USE THIS AS PRIMARY: ALL "text-white" SHOULD BE REPLACED WITH "text-gray-100" */
.text-white {
    color: #fff !important;
}

.text-default,
.text-enabled {
    color: var(--text-icons-gray-100) !important;
}

.text-inactive {
    color: var(--text-icons-gray-300) !important;
}

.text-hover-default:hover {
    color: var(--text-icons-gray-100);
}

/* #endregion */

/* #region BACKGROUND CUSTOM CSS CLASSES */
.bg-hover-blue:hover {
    border: 1px solid #00aeef;
}
/* #endregion */
